import { Mode } from "./Mode";
import { useTranslation } from "react-i18next";

export const ModePage = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="main-section">
      <div className="negative-left-margin-2">
        <h1 style={{ textAlign: "left", width: "300px" }}>
          {" "}
          CALOUSTE GULBENKIAN
        </h1>
      </div>
      <div className="mode-section">
        <Mode title={t("mode.title.normal_mode")} />
        <Mode title={t("mode.title.kids_mode")} />
      </div>
    </div>
  );
};
