import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./components/HomePage";
import { ModePage } from "./components/ModePage";
import { KeyPadPage } from "./components/KeypadPage";

const router = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/mode", element: <ModePage /> },
  { path: "/keypad", element: <KeyPadPage /> },
]);
function App() {
  return (
    <div className="app">
      <div className="main-container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
