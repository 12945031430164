import { useState } from "react";
import { Key } from "./Key";
export const KeyPadPage = () => {
  const [activeKey, setActiveKey] = useState();
  const [pause, setPause] = useState(false);
  const numberArray = [
    { id: "1", no: 1 },
    { id: "2", no: 2 },
    { id: "3", no: 3 },
    { id: "4", no: 4 },
    { id: "5", no: 5 },
    { id: "6", no: 6 },
    { id: "7", no: 7 },
    { id: "8", no: 8 },
    { id: "9", no: 9 },
    { id: "10", no: 10 },
    { id: "11", no: 11 },
    { id: "12", no: 12 },
  ];
  const handleChange = (number) => {
    setActiveKey(number);
    // if (number === activeKey) {
    //   setPause((e) => !e);
    // } else {
    //   setPause(true);
    // }
  };
  return (
    <div className="main-section">
      <div className="negative-left-margin-2">
        <h1 style={{ textAlign: "left", width: "300px" }}>
          {" "}
          CALOUSTE GULBENKIAN
        </h1>
      </div>
      <div
        style={{
          width: "400px",
          height: "400px",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          gap: "5px",
          flexWrap: "wrap",
          marginTop: "100px",
        }}
      >
        {numberArray.map((number) => {
          return (
            <Key
              key={number.id}
              number={number.no}
              activeKey={activeKey}
              handleChange={handleChange}
              pause={pause}
              setPause={setPause}
            />
          );
        })}
      </div>
    </div>
  );
};
