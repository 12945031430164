import { CustomButton } from "./CustomButton";

export const HomePage = () => {
  return (
    <div className="main-section">
      <div className="negative-left-margin-1">
        <h1 style={{ textAlign: "left" }}>GULBENKIAN </h1>
      </div>
      <div
        style={{
          width: "400px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <CustomButton title="United Kingdom" img="assets/u.png" lang="uk" />
        <CustomButton title="Portuguese" img="assets/p.png" lang="po" />
      </div>
    </div>
  );
};
