import { useEffect } from "react";
import { PauseOutlined } from "@ant-design/icons";

export const Key = ({ number, activeKey, handleChange, pause, setPause }) => {
  const audio = new Audio("assets/714_pt.mp3");

  const onClickHandler = () => {
    handleChange(number);
    if (pause) {
      setPause(false);
    } else {
      setPause(true);
    }
  };

  const playAudio = () => {
    audio.play();
  };

  const pauseAudio = () => {
    audio.pause();
  };

  useEffect(() => {
    pause ? playAudio() : pauseAudio();
    return () => {
      pauseAudio();
    };
  }, [pause]);

  return (
    <div className="key-container">
      <div
        className="key"
        style={{
          backgroundColor:
            activeKey === number && pause && "rgb(247, 244, 244)",
        }}
        onClick={onClickHandler}
      >
        <span
          style={{
            fontSize: "24px",
            fontWeight: "500",
            color: "rgb(73, 72, 72)",
          }}
        >
          {activeKey === number && pause ? <PauseOutlined /> : number}
        </span>
      </div>
    </div>
  );
};
