import { useNavigate } from "react-router-dom";

export const Mode = (props) => {
  const navigate = useNavigate();
  const changeModeHandler = () => {
    navigate("/keypad");
  };
  return (
    <div className="mode" onClick={changeModeHandler}>
      <div style={{ width: "300px", height: "50px" }}>
        <h2>{props.title}</h2>
      </div>
      <div style={{ width: "300px", height: "50px" }}>
        <hr
          style={{
            color: "rgb(209, 208, 208)",
            marginTop: "20px",
          }}
        />
      </div>
    </div>
  );
};
