import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CustomButton = ({ title, img, lang }) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  return (
    <div
      className="button-div"
      style={{
        width: "400px",
        height: "100px",
      }}
      onClick={() => {
        navigate("/mode");
        i18n.changeLanguage(lang);
      }}
    >
      <button
        className="custom-button"
        style={{
          width: "400px",
          height: "100px",
          backgroundColor: "rgb(73, 72, 72)",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "350px",
            height: "90px",
            gap: "10px",
          }}
        >
          <img
            src={img}
            alt={title}
            style={{ width: "90px", height: "90px", borderRadius: "50%" }}
          />

          <div style={{ lineHeight: "100px", color: " rgb(209, 208, 208)" }}>
            {title}
          </div>
        </div>
      </button>
    </div>
  );
};
